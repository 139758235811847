<template>
  <div class="pt-52">

    <div class="mode-center">
      <div class="py-10 h-screen max-h-96">
        <div class="py-6" v-if="adminUserName">
          Welcome admin user: <span class="text-amaranth">{{adminUserName}}</span>
        </div>
        <div>
          <h3 class=" text-3xl">You are entering PreviewMode.</h3><br/>
          <div v-if="!invalid">
            In PreviewMode you can see all items otherwise hidden by the calendar system.
          </div>
        </div>
        <div class="py-6" v-if="invalid">
          *** NOT_ALLOWED ***
        </div>
        <div class="py-6" v-if="dev">
          **********<br/>
          You are viewing the app on the local Development Server, you can enter PreviewMode without being logged in to the Admin<br/>
          **********
        </div>
        <div class="py-6" v-if="canAccess">
          <div class="p-3 rounded-md bg-amaranth inline-block text-white cursor-pointer" @click="enterPreviewMode">
            Yes, enter PreviewMode
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: [],
  components: {
  },
  data(){
    return{
      dev: false,
      canAccess: false,
      adminUserName: null,
      invalid: null
    }
  },
  mounted(){
      if(process.env.NODE_ENV !== 'production'){
         this.dev = true
         this.canAccess = true
      }else{
        this.getAdminUser()
      }
      this.emitter.on('admin_user_done', (e) => {
          if(this.globals.admin_user){
            this.adminUserName = this.globals.admin_user.first_name + ' ' + this.globals.admin_user.last_name
            this.canAccess = true
          }else{
            this.invalid = true
          }
      })      
  },
  methods:{
    getAdminUser(){
      this.emitter.emit('get_admin_user')
    },
    enterPreviewMode(){
      this.globals.api_mode = 'preview'
      console.log('enterPreviewMode')
      this.emitter.emit('fetch_previewMode')
    }
  }
}
</script>

<style scoped>
</style>
